/* eslint-disable react/function-component-definition*/
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import styles from '../../pages/styles/techPages.module.scss';
import skip from '../../images/technology/rda/skip-the-long-drive.jpg';
import all from '../../images/technology/rda/all-devices-supported.jpg';
import access from '../../images/technology/rda/access-every-device-from-everywhere.png';
import proactive from '../../images/technology/rda/stay-proactive-with-channel-testing.jpg';

export default function MobileTabCards({ id }) {
    const { t } = useTranslation();
    const [ cardData, setCardData ] = useState(0);

    const cardsContent = [
        {
            id: 0,
            image: skip,
            title: t('siteMetadata.remoteDeviceAccess.skip'),
            text: t('siteMetadata.remoteDeviceAccess.skipText'),
            alt: 'Skip the long drive'
        },
        {
            id: 1,
            image: all,
            title: t('siteMetadata.remoteDeviceAccess.allDevices'),
            text: t('siteMetadata.remoteDeviceAccess.allDevicesText'),
            alt: 'All devices supported'
        },
        {
            id: 2,
            image: access,
            title: t('siteMetadata.remoteDeviceAccess.access'),
            text: t('siteMetadata.remoteDeviceAccess.accessText'),
            alt: 'Access every device from anywhere'
        },
        {
            id: 3,
            image: proactive,
            title: t('siteMetadata.remoteDeviceAccess.proactive'),
            text: t('siteMetadata.remoteDeviceAccess.proactiveText'),
            alt: 'Stay proactive with channel testing'
        }
    ];

    const handleClick = (name, e) => {
        setCardData(name);
    };

    return (
        <section id={styles.interactiveSection}>
            <h2 id={styles.interactiveHeader}>
                {t('siteMetadata.remoteDeviceAccess.makeTheWorld')}
            </h2>
            <div className={styles.interactiveSectionWrapper}>
                <ul id={styles.interactiveList}>
                    {
                        cardsContent.map(card => (
                            <li
                                key={card.id}
                                className={styles.interactiveItem}
                                onClick={(e) => handleClick(card.id, e)}
                            >
                                <div className={styles.chevronButton}>
                                    <FontAwesomeIcon
                                        icon={cardData === card.id ? faChevronDown : faChevronRight}
                                    />
                                </div>
                                <div className={styles.cardText}>
                                    <h3 className={styles.cardTitle}>
                                        {card.title}
                                    </h3>
                                    <p className={styles.cardParagraph}>
                                        <p className={cardData === card.id ? styles.cardVisible : styles.cardInvisible}>
                                            {card.text}
                                        </p>
                                    </p>
                                </div>
                                <div className={styles.interactiveImage}>
                                    <img src={card.image} className={cardData === card.id ? styles.imageVisible : styles.imageInvisible} alt={card.alt} />
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </section>
    );
}