/* eslint-disable react/jsx-pascal-case*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../../components/common/styles/useCaseCarousel.css';
import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useOpenContactModal, useViewport } from '../../hooks';
import Video from '../../components/ui/Video';
import MobileTabCards from '../../components/common/MobileTabCards';
import TabCards from '../../components/common/TabCards';
import styles from '../styles/techPages.module.scss';
import homeStyles from '../styles/newHome.module.scss';
import mobileStyles from '../styles/useCasesMobile.module.scss';
import ogimage from '../../images/technology/rda/technology-og-image.png';
import laptopBlack from '../../images/technology/rda/remote-device-access-hero-laptop-frame-dark-screen.png';
import RECvideo from '../../images/technology/rda/remote-device-access-hero.mp4';
import rec from '../../images/technology/rda/remote-eye-controller-thumb.jpg';
import recMobile from '../../images/technology/rda/remote-eye-controller.jpg';
import witboxOne from '../../images/technology/rda/witbox-one-thumb.jpg';
import witboxOneMobile from '../../images/technology/rda/witbox-one.jpg';
import smartgate from '../../images/technology/rda/smartgate-thumb.jpg';
import smartgateMobile from '../../images/technology/rda/smartgate.jpg';
import witbeCloud from '../../images/technology/rda/witbe-cloud-thumb.jpg';
import witbeCloudMobile from '../../images/technology/rda/witbe-cloud.jpg';
import { MODAL_SOURCE_BUTTONS } from '../../constants/modal';

const { REMOTE_DEVICE_ACCESS } = MODAL_SOURCE_BUTTONS;

const RemoteDeviceAccess = () => {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;
    const breakpointTablets = 821;

    const openContactModal = useOpenContactModal(REMOTE_DEVICE_ACCESS);

    return (
        <Layout title='Remote Device Access'>
            <SEO
                title='Remote Device Access'
                ogDescription='With Witbe’s Remote Eye Controller, you can remotely test all of your devices, in real-time, from anywhere in the world – on your own computer or mobile device.'
                description='With Witbe’s Remote Eye Controller, you can remotely test all of your devices, in real-time, from anywhere in the world – on your own computer or mobile device.'
                image={ogimage}
            />
            <div id={styles.bannerDynamic}>
                <div id={styles.moduleContent}>
                    <div className={styles.bottom}>
                        <div className={styles.bottomWrapper}>
                            <h1 className={styles.mainImageTitle}>
                                {t('siteMetadata.remoteDeviceAccess.mainH1')}
                            </h1>
                            <div className={styles.animateWrapper}>
                                <div className={styles.deviceWrapper}>
                                    <img src={laptopBlack} loading='eager' alt='Laptop' />
                                </div>
                                <div className={styles.videoWrapper}>
                                    <Video
                                        isDecorative
                                        eagerLoading
                                        video={RECvideo}
                                        poster='https://www.witbe.net/remote-device-access-hero.png'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.top}>
                        <div className={styles.topWrapper}>
                            <h2 className={styles.mainTitleDynamic}>
                                {t('siteMetadata.remoteDeviceAccess.title')}
                            </h2>
                            <h3 className={styles.mainSubtitleH3}>
                                {t('siteMetadata.remoteDeviceAccess.description')}
                            </h3>
                            {width < breakpoint ?
                                (
                                    <div className={styles.learnMoreBuyButtons}>
                                        <div>
                                            <Link
                                                to='/technology/'
                                                className={styles.cardLearnMoreInline}
                                            >
                                                {t('siteMetadata.remoteDeviceAccess.explore')}
                                            </Link>
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={styles.chevronRightBlue}
                                            />
                                        </div>
                                        <div>
                                            <Link
                                                to='/articles/witbox-the-best-slingbox-alternative/'
                                                className={styles.cardLearnMoreInline}
                                            >
                                                {t('siteMetadata.remoteDeviceAccess.watch')}
                                            </Link>
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={styles.chevronRightBlue}
                                            />
                                        </div>
                                    </div>
                                )
                            :
                                (
                                    <div className={styles.learnMoreBuyButtons}>
                                        <div className={styles.cardLearnMoreInlineContainer}>
                                            <Link
                                                to='/technology/'
                                                className={styles.cardLearnMoreInlineNoMargin}
                                            >
                                                {t('siteMetadata.remoteDeviceAccess.explore')}
                                            </Link>
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={styles.chevronRight}
                                            />
                                        </div>
                                        <div className={styles.cardLearnMoreInlineContainer}>
                                            <Link
                                                to='/articles/witbox-the-best-slingbox-alternative/'
                                                className={styles.cardLearnMoreInline}
                                            >
                                                {t('siteMetadata.remoteDeviceAccess.watch')}
                                            </Link>
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={styles.chevronRight}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            {width < breakpointTablets ? <MobileTabCards /> : <TabCards />}
            {width < breakpoint ?
                (
                    <section id={styles.quadColSectionMobile}>
                        <div id={mobileStyles.header}>
                            <h2 id={styles.quadColTitleMobile}>
                                {t('siteMetadata.remoteDeviceAccess.discover')}
                            </h2>
                        </div>
                        <Carousel
                            showThumbs={false}
                            showArrows={false}
                            showStatus={false}
                            autoPlay={false}
                            interval={5000}
                            stopOnHover
                            centerMode
                            centerSlidePercentage={80}
                            swipeable
                            emulateTouch
                            infiniteLoop={false}
                            selectedItem={0}
                            transitionTime={1000}
                            showIndicators={false}
                            className='usecase-carousel'
                        >
                            <div className={mobileStyles.itemMobileWrapper}>
                                <div className={mobileStyles.itemMobile}>
                                    <div className={mobileStyles.cardImageWrapperMobile}>
                                        <img src={recMobile} className={mobileStyles.cover} alt='REC' />
                                    </div>
                                    <div className={mobileStyles.itemContentMobile}>
                                        <h3 className={mobileStyles.itemTitleMobile}>{t('siteMetadata.remoteDeviceAccess.rec')}</h3>
                                        <p className={mobileStyles.itemParagraphMobile}>{t('siteMetadata.remoteDeviceAccess.recText')}</p>
                                        <p className={mobileStyles.itemLearnMobile}>
                                            <Link
                                                to='/technology#suite'
                                                className={styles.itemLearnMobile}
                                            >
                                                {t('siteMetadata.remoteDeviceAccess.learn')}
                                            </Link>
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={styles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={mobileStyles.itemMobileWrapper}>
                                <div className={mobileStyles.itemMobile}>
                                    <div className={mobileStyles.cardImageWrapperMobile}>
                                        <img src={witboxOneMobile} className={mobileStyles.cover} alt='Witbox One' />
                                    </div>
                                    <div className={mobileStyles.itemContentMobile}>
                                        <h3 className={mobileStyles.itemTitleMobile} dangerouslySetInnerHTML={{__html: t('siteMetadata.remoteDeviceAccess.witboxOne')}}></h3>
                                        <p className={mobileStyles.itemParagraphMobile} dangerouslySetInnerHTML={{__html: t('siteMetadata.remoteDeviceAccess.witboxOneText')}}></p>
                                        <p className={mobileStyles.itemLearnMobile}>
                                            <Link
                                                to='/products/witbox'
                                                className={styles.itemLearnMobile}
                                            >
                                                {t('siteMetadata.remoteDeviceAccess.learn')}
                                            </Link>
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={styles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={mobileStyles.itemMobileWrapper}>
                                <div className={mobileStyles.itemMobile}>
                                    <div className={mobileStyles.cardImageWrapperMobile}>
                                        <img src={smartgateMobile} className={mobileStyles.cover} alt='Smartgate' />
                                    </div>
                                    <div className={mobileStyles.itemContentMobile}>
                                        <h3 className={mobileStyles.itemTitleMobile}>{t('siteMetadata.remoteDeviceAccess.smartgate')}</h3>
                                        <p className={mobileStyles.itemParagraphMobile}>{t('siteMetadata.remoteDeviceAccess.smartgateText')}</p>
                                        <p className={mobileStyles.itemLearnMobile}>
                                            <Link
                                                to='/technology#suite'
                                                className={styles.itemLearnMobile}
                                            >
                                                {t('siteMetadata.remoteDeviceAccess.learn')}
                                            </Link>
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={styles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={mobileStyles.itemMobileWrapper}>
                                <div className={mobileStyles.itemMobile}>
                                    <div className={mobileStyles.cardImageWrapperMobile}>
                                        <img src={witbeCloudMobile} className={mobileStyles.cover} alt='Witbe Cloud Devices' />
                                    </div>
                                    <div className={mobileStyles.itemContentMobile}>
                                        <h3 className={mobileStyles.itemTitleMobile}>{t('siteMetadata.remoteDeviceAccess.witbeCloudDevices')}</h3>
                                        <p className={mobileStyles.itemParagraphMobile}>{t('siteMetadata.remoteDeviceAccess.witbeCloudDevicesText')}</p>
                                        <p className={mobileStyles.itemLearnMobile}>
                                            <Link
                                                to='/technology#suite'
                                                className={styles.itemLearnMobile}
                                            >
                                                {t('siteMetadata.remoteDeviceAccess.learn')}
                                            </Link>
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={styles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Carousel>
                    </section>
                )
                :
                (
                    <section id={styles.quadColSection}>
                        <h2 id={styles.quadColTitle}>
                            {t('siteMetadata.remoteDeviceAccess.discover')}
                        </h2>
                        <ul id={styles.quadColList}>
                            <Link
                                to='/technology#suite'
                            >
                                <li className={styles.item}>
                                    <LazyLoad offset={400} className={styles.itemThumb}>
                                        <img src={rec} className={styles.itemIcon} alt='REC' />
                                    </LazyLoad>
                                    <div className={styles.itemText}>
                                        <h3 className={styles.itemTitle}>{t('siteMetadata.remoteDeviceAccess.rec')}</h3>
                                        <p className={styles.itemParagraph}>{t('siteMetadata.remoteDeviceAccess.recText')}</p>
                                        <p className={styles.itemLearnMore}>
                                            {t('siteMetadata.remoteDeviceAccess.learn')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={styles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <Link
                                to='/products/witbox/'
                            >
                                <li className={styles.item}>
                                    <LazyLoad offset={400} className={styles.itemThumb}>
                                        <img src={witboxOne} className={styles.itemIcon} alt='Witbox One' />
                                    </LazyLoad>
                                    <div className={styles.itemText}>
                                        <h3 className={styles.itemTitle} dangerouslySetInnerHTML={{__html: t('siteMetadata.remoteDeviceAccess.witboxOne')}}>
                                        </h3>
                                        <p className={styles.itemParagraph} dangerouslySetInnerHTML={{__html: t('siteMetadata.remoteDeviceAccess.witboxOneText')}}></p>
                                        <p className={styles.itemLearnMore}>
                                            {t('siteMetadata.remoteDeviceAccess.learn')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={styles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <Link
                                to='/technology#suite'
                            >
                                <li className={styles.item}>
                                    <LazyLoad offset={400} className={styles.itemThumb}>
                                        <img src={smartgate} className={styles.itemIcon} alt='Smartgate' />
                                    </LazyLoad>
                                    <div className={styles.itemText}>
                                        <h3 className={styles.itemTitle}>
                                            {t('siteMetadata.remoteDeviceAccess.smartgate')}
                                        </h3>
                                        <p className={styles.itemParagraph}>{t('siteMetadata.remoteDeviceAccess.smartgateText')}</p>
                                        <p className={styles.itemLearnMore}>
                                            {t('siteMetadata.remoteDeviceAccess.learn')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={styles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <Link
                                to='/technology#suite'
                            >
                                <li className={styles.item}>
                                    <LazyLoad offset={400} className={styles.itemThumb}>
                                        <img src={witbeCloud} className={styles.itemIcon} alt='Witbe Cloud' />
                                    </LazyLoad>
                                    <div className={styles.itemText}>
                                        <h3 className={styles.itemTitle}>
                                            {t('siteMetadata.remoteDeviceAccess.witbeCloudDevices')}
                                        </h3>
                                        <p className={styles.itemParagraph}>{t('siteMetadata.remoteDeviceAccess.witbeCloudDevicesText')}</p>
                                        <p className={styles.itemLearnMore}>
                                            {t('siteMetadata.remoteDeviceAccess.learn')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={styles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                        </ul>
                    </section>
                )
            }
            <section id={styles.blueSection}>
                <h2 id={styles.blueSectionTitleFullWidth}>
                    {t('siteMetadata.remoteDeviceAccess.readyTo')}
                </h2>
                {width < breakpoint ?
                    (
                        <div className={homeStyles.witboxButtons}>
                            <Link
                                to='/technology/remote-device-access/'
                                className={homeStyles.whiteButtonMobile + ' ' + homeStyles.buyButton}
                                onClick={openContactModal}
                            >
                                {t('siteMetadata.home.contactUs')}
                            </Link>
                        </div>
                    )
                    :
                    (
                        <div className={homeStyles.witboxButtons}>
                            <Link
                                to='/technology/remote-device-access/'
                                className={homeStyles.whiteButton + ' ' + homeStyles.buyButton}
                                // className={newStyles.whiteButton + ' ' + newStyles.buyButton}
                                onClick={openContactModal}
                            >
                                {t('siteMetadata.home.contactUs')}
                            </Link>
                        </div>
                    )
                }

            </section>
        </Layout>
    );
};

export default RemoteDeviceAccess;
